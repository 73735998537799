.input-field {
  border: 1px solid #000000;
  border-radius: 20px;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    padding-left: 40px;
  }

  & + label:before {
    position: absolute;
    top: 4px;
    left: 0;
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 25px;
    height: 25px;
    background: white;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 5px;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 7px;
    width: 19px;
    height: 19px;
    background: #458c76;
    border-radius: 3px;
  }
}
