.maximizable-container {
  &.app-root {
    padding-bottom: var(--nav-size);
    background-color: white;
    overflow: auto;
  }
  &.fullscreen {
  }
  &.default {
  }
  .maximizable-content {
  }
  .maximizable-actions {
    button {
      position: fixed;
      top: 10px;
      right: 10px;
    }
  }
}
