.landing-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  min-height: calc(100vh - var(--header-size));
  min-height: calc((var(--vh, 1vh) * 100) - var(--header-size));
  margin-bottom: calc(var(--nav-size) - (var(--nav-size) * 2));
  .top-area {
    align-self: flex-start;
    width: 100%;
    margin-bottom: auto;
    flex-grow: 1;
  }
  .bottom-area {
    align-self: flex-end;
    width: 100%;
    margin-top: auto;

    .product-image {
      position: relative;
      overflow: hidden;

      &:after {
        content: ' ';
        position: absolute;
        background-color: var(--brand-color-lighter);
        height: 600px;
        width: 650px;
        border-radius: 100%;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -100px);
        z-index: 1;
      }

      img {
        position: relative;
        z-index: 2;
        margin: auto;
        max-width: 100%;
        height: 220px;
        display: block;
      }
    }
  }
}
