.test-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  > li {
    position: relative;
    border-bottom: 1px solid var(--brand-color-deep);
    padding: 15px 145px 15px 30px;
    cursor: pointer;
    .test-dot {
      height: 20px;
      width: 20px;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
    .test-title {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      text-transform: uppercase;
      display: block;
    }
    .test-subtitle {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      display: block;
    }
    .residue {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 32px;
      transform: translate(0, -50%);
    }
    .test-plus-sign {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translate(0, -50%);
    }
  }
}

.has-dot {
  padding-left: 20px;
  position: relative;
}

.left-dot-1,
.left-dot-2 {
  position: absolute;
  top: -10px;
  height: 50px;
  width: 25px;
  display: block;
  left: -16px;
  object-fit: cover;
  object-position: right;
}

.left-dot-2 {
  top: 0;
}

.molecule-items {
  text-align: center;
  .molecule-item {
    display: inline-block;
    padding: 10px;
    img {
      width: 20px;
      margin-right: 5px;
    }
  }
}
