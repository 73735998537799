.brand-modal {
  .modal-content {
    border-radius: 0;
  }

  .modal-header {
    border: 0;
    border-radius: 0;
    min-height: 70px;
  }

  .close-btn {
    background: rgba(255, 255, 255, 0.8);
    height: 50px;
    width: 50px;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 100%;
    z-index: 10000000;
    text-align: center;
    line-height: 50px;
    img {
      height: 21px;
      width: 21px;
      display: inline-block;
    }
  }

  .modal-body {
    padding-top: 0;
  }
  .back {
    display: inline-block;
    img {
      display: inline-block;
      height: 45px;
    }
  }
}
