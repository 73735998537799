@import '../../fonts/HugMeTight/HugMeTight';
@import '../../fonts/AvenirNext/AvenirNext';

:root {
  --brand-color-deep: #006646;
  --brand-color-light: #458c76;
  --brand-color-lighter: #73a696;
  --dark: #000000;
  --gray: #979797;
  --red: #d53626;
  --nav-size: 61px;
  --header-size: 85px;
}

body {
  background-color: white;
  color: var(--dark);
  font-family: 'Avenir Next', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.reset-ul {
  padding-left: 16px;
}

.reset-btn {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  /* Remove excess padding and border in Firefox 4+ */

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:focus {
    outline: none;
  }
}

.color-brand-deep {
  color: var(--brand-color-deep);
}

.color-brand-light {
  color: var(--brand-color-light);
}

.color-brand-lighter {
  color: var(--brand-color-lighter);
}
.color-gray {
  color: var(--gray);
}

.bg-brand-deep {
  background-color: var(--brand-color-deep);
}

.bg-brand-light {
  background-color: var(--brand-color-light);
}

.bg-brand-lighter {
  background-color: var(--brand-color-lighter);
}

.headline-1,
.headline-2,
.headline-3 {
  font-family: 'Hug Me Tight', cursive;
}

.headline-1 {
  font-size: 30px;
}

.headline-2 {
  font-size: 25px;
}

.headline-3 {
  font-size: 16px;
}

.title-text,
.body-text-large,
.body-text-medium,
.body-text-small,
.link-text-large,
.link-text-medium,
.link-text-small {
  font-family: 'Avenir Next', Avenir, sans-serif;
}

.title-text {
  font-size: 14px;
  font-weight: 700;
}

.body-text-large {
  font-size: 16px;
  font-weight: 500;
}

.body-text-medium,
.link-text-medium {
  font-size: 14px;
  font-weight: 500;
}

.body-text-small,
.link-text-small {
  font-size: 12px;
  font-weight: 500;
}

.link-text-large,
.link-text-small,
.link-text-medium {
  text-decoration: underline;
}

.link-text-large {
  font-size: 16px;
  font-weight: 600;
}

.min-width-120 {
  min-width: 120px;
}

.marginless {
  margin-left: -1rem;
  margin-right: -1rem;
}

.paddingless {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}

.min-width-210 {
  min-width: 210px;
}

.brand-button-white {
  color: var(--brand-color-lighter);
  border-radius: 0.5rem;
  background: white;
  line-height: 40px;
}

.brand-button {
  color: white;
  border-radius: 0.5rem;
  background: var(--brand-color-light);
  line-height: 40px;
}

.gray-button {
  color: white;
  border-radius: 0.5rem;
  background: var(--gray);
  line-height: 40px;
}

.divider {
  margin: 35px auto;
  width: 102px;
  border: 2px solid var(--brand-color-deep);
}

.mt-60 {
  margin-top: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}
