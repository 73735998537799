.test-details-list {
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 16px;
  border-left: 1px solid var(--brand-color-lighter);
  li {
    position: relative;
    top: -11px;
    .date-tag {
      padding: 2px 10px;
      border-radius: 5px;
    }
    &:before {
      position: absolute;
      content: ' ';
      top: 10px;
      left: -22px;
      height: 11px;
      width: 11px;
      border-radius: 100%;
      background: var(--brand-color-deep);
    }
  }
}
.gras{
  font-weight: 700;
}
