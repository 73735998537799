$FontPath: '@font-face';
$FontName: 'Hug Me Tight';

@font-face {
  font-family: $FontName;
  src: url('#{$FontPath}/4b46ff5fc6c566874b6d4337ca4fd0a5.eot'); /* IE9*/
  src: url('#{$FontPath}/4b46ff5fc6c566874b6d4337ca4fd0a5.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$FontPath}/4b46ff5fc6c566874b6d4337ca4fd0a5.woff2') format('woff2'),
    /* chrome、firefox */ url('#{$FontPath}/4b46ff5fc6c566874b6d4337ca4fd0a5.woff') format('woff'),
    /* chrome、firefox */ url('#{$FontPath}/4b46ff5fc6c566874b6d4337ca4fd0a5.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('#{$FontPath}/4b46ff5fc6c566874b6d4337ca4fd0a5.svg##{$FontName}') format('svg'); /* iOS 4.1- */
}
