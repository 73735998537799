.nav-menu {
  background-color: var(--brand-color-light);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 9px 0 5px 0;
  border-top: 1px solid white;
  z-index: 1000;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-end;
    li {
      flex-grow: 1;
      flex-basis: 0;
      a {
        display: block;
        text-decoration: none;
        color: white;
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        .nav-text {
          opacity: 0.7;
          display: block;
        }
        img {
          display: block;
          margin: 0 auto 5px auto;
          height: 23px;
          object-fit: contain;
          object-position: center;
        }
        .regular-icon {
        }
        .active-icon {
          display: none;
        }
        &.active-menu,
        &:hover {
          .regular-icon {
            display: none;
          }
          .active-icon {
            display: block;
          }
          .nav-text {
            opacity: 1;
          }
        }
      }
    }
  }
}
