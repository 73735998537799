.comingsoon-page {
  height: 700px;
  background: #006646;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  .load-image{
    margin-top: 10px;
    flex-grow: 1;
    margin-left: 38%;
  }
}
.load p{
  margin-top: 30px;
  font-family: Hug Me Tight;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}
