$FontPath: '@font-face';
$FontName: 'Avenir Next';

@font-face {
  font-family: $FontName;
  font-weight: 400;
  src: url('#{$FontPath}/avenir-next-regular.eot'); /* IE9*/
  src: url('#{$FontPath}/avenir-next-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$FontPath}/avenir-next-regular.woff') format('woff'),
    /* chrome、firefox */ url('#{$FontPath}/avenir-next-regular.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/ url('#{$FontPath}/avenir-next-regular.svg##{$FontName}')
      format('svg'); /* iOS 4.1- */
}

@font-face {
  font-family: $FontName;
  font-weight: 500;
  src: url('#{$FontPath}/avenir-next-medium.eot'); /* IE9*/
  src: url('#{$FontPath}/avenir-next-medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$FontPath}/avenir-next-medium.woff') format('woff'),
    /* chrome、firefox */ url('#{$FontPath}/avenir-next-medium.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/ url('#{$FontPath}/avenir-next-medium.svg##{$FontName}')
      format('svg'); /* iOS 4.1- */
}

@font-face {
  font-family: $FontName;
  font-weight: 600;
  src: url('#{$FontPath}/avenir-next-demi.eot'); /* IE9*/
  src: url('#{$FontPath}/avenir-next-demi.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$FontPath}/avenir-next-demi.woff') format('woff'),
    /* chrome、firefox */ url('#{$FontPath}/avenir-next-demi.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/ url('#{$FontPath}/avenir-next-demi.svg##{$FontName}')
      format('svg'); /* iOS 4.1- */
}

@font-face {
  font-family: $FontName;
  font-weight: 700;
  src: url('#{$FontPath}/avenir-next-bold.eot'); /* IE9*/
  src: url('#{$FontPath}/avenir-next-bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$FontPath}/avenir-next-bold.woff') format('woff'),
    /* chrome、firefox */ url('#{$FontPath}/avenir-next-bold.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/ url('#{$FontPath}/avenir-next-bold.svg##{$FontName}')
      format('svg'); /* iOS 4.1- */
}

@font-face {
  font-family: $FontName;
  font-weight: 800;
  src: url('#{$FontPath}/avenir-next-heavy.eot'); /* IE9*/
  src: url('#{$FontPath}/avenir-next-heavy.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$FontPath}/avenir-next-heavy.woff') format('woff'),
    /* chrome、firefox */ url('#{$FontPath}/avenir-next-heavy.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/ url('#{$FontPath}/avenir-next-heavy.svg##{$FontName}')
      format('svg'); /* iOS 4.1- */
}
