.search-block {
  .search-input-group {
    label {
      position: relative;
      padding-bottom: 3px;
      img {
        position: absolute;
        top: 5px;
        right: -40px;
      }
    }
    .search-input-group-con {
      max-width: 280px;
      margin: auto;
      position: relative;
      .input-wrapper {
        width: 100%;
        background-color: transparent;
        border-radius: 20px;
        position: relative;
        input {
          font-family: 'Hug Me Tight', cursive;
          border-radius: 20px;
          display: block;
          width: 100%;
          border: 1px solid white;
          background-color: transparent;
          color: white;
          line-height: 1;
          font-size: 25px;
          letter-spacing: 5px;
          padding: 7px 20px;
          -webkit-appearance: none;
          -moz-appearance: textfield;
          position: relative;
          z-index: 5;
          vertical-align: middle;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
        }
        .search-input-placeholder {
          position: absolute;
          top: 16px;
          left: 50%;
          transform: translate(-50%, 0);
          width: 100%;
          z-index: 3;
          span {
            display: inline-block;
            width: 12px;
            border-bottom: 1px solid white;
            margin: 0 1px;
            // &:nth-child(8) {
            //   top: -9px;
            //   position: relative;
            //   width: 10px;
            //   border: 2px solid white;
            //   border-radius: 20px;
            //   margin: 0 5px;
            //   z-index: 50;
            // }
          }
        }
      }
      .auto-complete {
        position: absolute;
        top: calc(100% - 2px);
        left: -1px;
        right: -1px;
        background: #ffffff;
        display: none;

        .auto-complete-i {
          font-weight: 500;
          font-size: 16px;
          line-height: 33px;
          border: 1px solid var(--brand-color-lighter);
          border-bottom: 0;
          text-align: left;
          padding-left: 20px;
          letter-spacing: 5px;
          cursor: pointer;

          &:last-child {
            border-bottom: 1px solid var(--brand-color-lighter);
          }
        }
      }
      &.has-input-focus,
      &.has-suggestion {
        input {
          color: var(--brand-color-lighter);
          outline: none;
        }
      }
      &.has-suggestion {
        .input-wrapper,
        input {
          border-radius: 20px 20px 0 0;
        }

        .auto-complete {
          display: block;
        }
      }
      &.has-error {
        input {
          border: 1px solid var(--red);
          color: var(--red);
        }
      }
      &.has-error,
      &.has-input-focus,
      &.has-suggestion {
        .input-wrapper {
          background-color: white;
        }
      }
      &.has-input-focus,
      &.has-value {
        .search-input-placeholder {
          span {
            visibility: hidden;

            // &:nth-child(8) {
            //   visibility: visible;
            //   border: 2px solid var(--brand-color-light);
            // }
          }
        }
      }
      &.has-value {
        .search-input-placeholder {
          span {
            &:nth-child(8) {
              border: 2px solid white;
            }
          }
        }
      }
      &.has-input-focus,
      &.has-suggestion {
        .search-input-placeholder {
          span {
            &:nth-child(8) {
              border: 2px solid var(--brand-color-light);
            }
          }
        }
      }
      &.has-error {
        .search-input-placeholder {
          span {
            border-bottom: 1px solid var(--red);
            &:nth-child(8) {
              border: 2px solid var(--red);
            }
          }
        }
      }
    }
  }
}
