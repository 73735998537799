.header {
  padding-top: 20px;
  padding-bottom: 20px;
  height: var(--header-size);
  .back {
    display: inline-block;
    img {
      display: inline-block;
      height: 45px;
    }

  }
  .header {
    text-align: center;
    display: block;

    img {
      display: inline-block;
      height: 45px;
    }
  }
}