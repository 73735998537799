.result-box {
  border: 1px solid var(--brand-color-lighter);
  border-radius: 20px;
  padding: 20px 20px 40px 20px;
  background-color: white;
}

.bottle-box {
  display: flex;
  align-items: center;
  .bottle-image {
    width: 110px;
  }
  .bottom-box {
    width: 100%;
    margin-right: 15px;
  }
}
.bottle-image {
  img {
    display: block;
    width: 145px;
    cursor: pointer;
  }
}

.find-note {
  position: relative;
  padding-left: 60px;
  img {
    position: absolute;
    bottom: 12px;
    left: 30px;
  }
}
