.cer-container {
  .cer-item {
    margin-bottom: 15px;
    img {
      display: block;
      margin: auto;
      max-height: 50px;
    }
    a {
      display: block;
      margin-top: 5px;
    }
  }
}
