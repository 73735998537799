.report-list {
  background: var(--gray);
  opacity: 0.7;
  padding: 12px 24px;
  & > .row > * {
    padding-right: 6px;
    padding-left: 6px;
  }
  img {
    cursor: pointer;
  }
}
.pg-viewer-wrapper{
  overflow-y: auto;
  .photo-viewer-container{
    width: 100% !important;
    height:  100% !important;
  }
  img{
    width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
  }
}
