.video-player {
  min-height: 250px;
  .video-overlay {
    border: 1px solid #c4c4c4;
    position: relative;
    width: 100%;
    .video-thumbnail {
      width: 100%;
      display: block;
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -22px;
      margin-top: -22px;
      width: 50px;
      height: 50px;
      background-image: url('../../images/play_icon.svg');
      background-size: contain;
      background-position: center center;
    }
  }
}

.brand-quote {
  background: #006646;
  max-width: 285px;
  margin-left: auto;
  margin-top: -60px;
  padding: 30px 20px;
  border-radius: 50px 0 0 0;
  z-index: 100;
  position: relative;
  opacity: 0.93;
}

.w-100 {
  width: 200px!important;
  height: auto;
}

#userPhoto{
  height: 220px;
  width: 100px
}