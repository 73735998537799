.formula-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  > li {
    position: relative;
    border-bottom: 1px solid var(--brand-color-deep);
    padding: 15px 22px 15px 0;
    cursor: pointer;
    .test-plus-sign {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translate(0, -50%);
    }
  }
}
.gras{
  font-weight: 700;
}
